const LedgerTableHeader = () => {
	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Type</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Debit</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Credit</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Fee</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Credit Fee</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Debit Fee</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Net</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Balance</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Destination</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Reference</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx ID</p>
				</div>
			</div>
		</div>
	);
};

export default LedgerTableHeader;
