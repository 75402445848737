import { AxiosPromise, AxiosResponse } from 'axios';
import { IUser } from 'redux/reducers/auth/types';
import {
	IUsersResponse,
	IRequiredEmailsResponse,
	I2FAResetPayload,
	I2FARejectPayload,
	IUsersFeeResponse,
	IUserFeesRequest,
	IUserFeesUpdteRequestPayload,
	IUserFeesUpdteSpreadRequestPayload,
	IUsersFeeSpreadResponse,
	IUserReferralRequestPayload,
	ICheckFireblockIdRequest,
	IUserFileDownloadPayload,
} from 'redux/reducers/users/types';
import { IGetFilePayload } from '../transactions/types';
import { ECoinType } from '../../../components/tables/WalletsTable/types';

export interface IUsersApi {
	getUsers: (payload: IUsersRequest) => Promise<IUsersResponse>;
	getUsersPermission: (payload: IUsersRequest) => Promise<IUsersResponse>;
	updateUserBlockStatus: (payload: IRequestBlocked) => Promise<IUser>;
	updateUserArchiveStatus: (payload: IUserArchivedRequest) => Promise<IUser>;
	getOneUser: (params: IApiGetOneUserParams) => Promise<IApiGetOneUserResponse>;
	getUserWallets: (payload: IUserWalletsRequest) => Promise<IUserWalletsResponse>;
	getUserReferral: (payload: IUserReferralRequest) => AxiosPromise;
	deleteUserReferral: (payload: IDeleteUserReferalRequestPayload) => Promise<IUserReferralResponse>;
	referralSystem: (payload: any) => Promise<any>;
	getUserLiquidityProvider: (payload: ILiquidityProviderRequest) => Promise<IUserReferralResponse>;
	getUserTradingLimits: (payload: ITradingLimitsRequest) => Promise<ITradingLimitsResponse>;
	getUserTransferLimits: (payload: ITransferLimitsRequest) => Promise<ITransferLimitsResponse[]>;
	// getUserTradingLimitsCrypto: (payload: ITradingLimitsRequest) => Promise<ITradingLimitsResponse>;
	// getUserTradingLimitsFiat: (payload: ITradingLimitsRequest) => Promise<ITradingLimitsResponse>;
	createUser: any;
	updateUserTradingLimit: (
		payload: IUpdateTradingLimitRequestPayload,
	) => Promise<ITradingLimitsResponse>;
	updateUserTransferLimit: (payload: IUpdateTransferLimitRequestPayload) => Promise<any>;
	getResetRequests: (payload: any) => AxiosPromise;
	reset2FA: (payload: I2FAResetPayload) => AxiosPromise;
	reject2FA: (payload: I2FARejectPayload) => AxiosPromise;
	getUserRequiredEmails: (payload: IUserIdPayload) => AxiosPromise;
	getUserRequiredEmailsTypeUser: (payload: IUserRequiredEmailsTypeUser) => AxiosPromise;
	getUserRequiredEmailsTypeAdmin: (payload: IUserRequiredEmailsTypeAdmin) => AxiosPromise;
	postUserRequiredEmails: (payload: IUserEmailsPutRequestPayload) => AxiosPromise;
	getAdminRequiredEmails: () => AxiosPromise;
	postAdminRequiredEmails: (payload: IUserEmailsPutRequestPayload) => AxiosPromise;
	updateUser: (payload: IUpdateUserPayload) => AxiosPromise;
	getAdminResetData: (payload: any) => Promise<AxiosPromise>;
	resetAdminPassword: (payload: any) => Promise<AxiosPromise>;
	rejectAdminPassword: (payload: any) => Promise<AxiosPromise>;
	getUserFee: (params: IUserFeesRequest) => Promise<IUsersFeeResponse>;
	putUserFee: (params: IUserFeesUpdteRequestPayload) => Promise<AxiosPromise>;
	getUserSpreadFee: (params: IUserFeesRequest) => Promise<IUsersFeeSpreadResponse>;
	putUserSpreadFee: (params: IUserFeesUpdteSpreadRequestPayload) => Promise<AxiosPromise>;
	getAdminTwoFaRequestsData: (payload: any) => Promise<AxiosPromise>;
	approveAdminTwoFaRequest: (payload: any) => Promise<AxiosPromise>;
	rejectAdminTwoFaRequest: (payload: any) => Promise<AxiosPromise>;
	putUserReferral: (payload: IUserReferralRequestPayload) => Promise<AxiosPromise>;
	getUsersPasswordResetRequests: (payload: any) => Promise<AxiosPromise>;
	resetUserPassword: (payload: any) => Promise<AxiosPromise>;
	rejectUserPassword: (payload: any) => Promise<AxiosPromise>;
	getUsersFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	setUserStatus: (payload: { id: number; status: number }) => Promise<AxiosPromise>;
	setUserFireblockId: (payload: {
		userId: number;
		data: { fireblocks_vault_id: number; totp: string };
	}) => Promise<AxiosPromise>;
	postAllTransferToPool: (payload: any) => Promise<AxiosPromise>;
	generateCodeForZip: (userId: number) => Promise<ISecretCodeResponse>;
	getCheckValidFireblockId: ({
		userId,
		fireblockId,
	}: ICheckFireblockIdRequest) => Promise<AxiosPromise>;

	downloadFile: (payload: string) => Promise<AxiosPromise>;
}

export interface IUpdateUserPayload {
	id: number;
	settings: any;
}
export interface ILiquidityProviderRequest {
	userId: string;
	liquidity_provider: string;
}

export interface IUserEmailsPutRequestPayload {
	id: string;
	type: boolean;
	settings: IRequiredEmailsResponse;
}

export interface IUserIdPayload {
	id: string;
	type: boolean;
}

export interface IUserRequiredEmailsTypeUser {
	id: string;
}

export interface IUserRequiredEmailsTypeAdmin {
	id: string;
}

export interface ITradingLimitsApiParams {
	current_page: number;
	per_page: number | undefined;
}

export interface ITradingLimitsRequest {
	id: string;
	type: boolean;
	params: ITradingLimitsApiParams;
}

export interface ITransferLimitsRequest {
	user_id: string;
	type: string;
}

export interface IUpdateTradingLimitRequest {
	trading_limit_id: number;
	onSuccess?: () => void;
	data: {
		limit_min: number;
		limit_max: number;
	};
}

export interface IUpdateTransferLimitRequest {
	onSuccess?: () => void;
	data: {
		minimum: number;
		maximum: number;
		hourly: number;
		daily: number;
	};
}

export interface IUpdateTradingLimitRequestPayload extends IUpdateTradingLimitRequest {
	userId: string;
}

export interface IUpdateTransferLimitRequestPayload extends IUpdateTransferLimitRequest {
	id: string | number;
}

export interface ITradingLimitsResponse {
	current_page: number;
	next_page_url: string;
	data: Array<IUserTradingLimitsItem>;
	path: string;
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
}

export interface ITransferLimitsResponse {
	data: ITransferLimitsItem[] | null;
}

export interface ITransferLimitsItem {
	asset: IUserTransferLimitsAsset;
	asset_id: number;
	created_at: string;
	daily: number;
	hourly: number;
	id: number;
	maximum: number;
	minimum: number;
	updated_at: string;
	user_id: number;
}

export interface IUserTransferLimitsAsset {
	id: number;
	code: string;
	type: string;
	depositable: number;
	withdrawable: number;
	exchangeable: number;
	depositable_message: string | null;
	withdrawable_message: string | null;
	has_tag: number;
	decimals: string;
	name: string;
	deposit_max: number;
	deposit_min: number;
	withdraw_max: number;
	withdraw_min: number;
	exchange_min: number;
	img_path: string;
}

export interface IUserTradingLimitsItem {
	id: number;
	user_id: number;
	asset_id: number;
	limit_min: number;
	limit_max: number;
	asset: IUserTradingLimitsAsset;
	chain_id: number;
	address_id: number;
	amount: number;
	fee: number;
	net_fee: number;
	gross_fee: number;
	destination: string;
	tag: string;
	tx_id: string;
	tx_hash: string;
	type: string;
	network_fee: string;
	status: string;
	created_at: string;
	updated_at: string;
	chain: IUsersChain;
	address: string;
}

export interface IUsersChain {
	id: number;
	name: string;
	code: string;
	created_at: string;
	updated_at: string;
}

export interface IUserTradingLimitsAsset {
	id: number;
	code: string;
	type: string;
	depositable: number;
	withdrawable: number;
	exchangeable: number;
	depositable_message: string;
	withdrawable_message: string;
	has_tag: number;
	decimals: string;
	name: string;
	deposit_max: number;
	deposit_min: number;
	withdraw_max: number;
	withdraw_min: number;
	exchange_min: number;
	img_path: string;
}
export interface IUsersRequest {
	search_value?: string;
	seqarch_name?: string;
	search_field?: string;
	per_page?: number;
	current_page?: number;
	status?: TApiUsersStatus;
	permission?: boolean;
	status_field?: string;
	status_value?: number;
}

export interface IRequestBlocked {
	id: number;
	event: string;
	query?: IUsersRequest;
}

export interface IUserArchivedRequest {
	userId: number;
	event: string;
	query?: IUsersRequest;
}

export interface IUserWalletsRequest {
	user_id: number | string;
}

// 📌 Common

export enum ApiUserStatus {
	Unverifed = 1,
	Verifed = 2,
	Pending = 3,
	Rejected = 4,
}

// 📌 One user

export interface IApiGetOneUserParams {
	userId: number | string;
}

// export interface IApiGetOneUserData {
// 	user_id: number;
// 	// legacy_id: null;
// 	first_name: string | null;
// 	last_name: string | null;
// 	phone: string | null;
// 	// account_number: null;
// 	communication_channel: string | null;
// 	communication_data: string | null;
// 	commission: string;
// 	deposit_bank_account: string | null;
// 	fireblocks_vault_id: string | null;
// 	created_at: string;
// 	updated_at: string;
// 	liquidity_provider: string;
// 	company_name: string | null;
// 	registration_country: string | null;
// 	registration_number: string | null;
// 	website: string | null;
// 	bank_name: string | null;
// 	account_number: string | null;
// 	swift_bic: string | null;
// 	beneficiary_name: string | null;
// 	beneficiary_address: string | null;
// 	bank_address: string | null;
// 	bank_currency: string | null;
// 	position: string | number | null;
// }

export interface IApiGetOneUserData {
	oneify_id: number;
	user_id: number;
	// legacy_id: null;
	first_name: string | null;
	last_name: string | null;
	phone: string | null;
	// account_number: null;
	communication_channel: string | null;
	communication_data: string | null;
	commission: string;
	deposit_bank_account: string | null;
	fireblocks_vault_id: string | null;
	created_at: string;
	updated_at: string;
	liquidity_provider: string;
	company_name: string | null;
	company_country_registration: string | null;
	company_registration_number: string | null;
	company_website: string | null;
	bank_nickname: string | null;
	account_number: string | null;
	bank_swift_bic: string | null;
	bank_beneficiary_name: string | null;
	bank_beneficiary_address: string | null;
	bank_address: string | null;
	bank_currency: string | null;
	bank_account_number: string | null;
	position: string | number | null;
	account_type: string | null;
}

export interface IApiGetOneUserCompany {
	user_id: number;
	company_name: string | null;
	registration_country: string | null;
	trading_activities: string | null;
	trading_frequency: string | null;
	average_transaction_size: string | null;
	// business_income: null;
	business_name: string | null;
	// business_amount: null;
	// business_date: null;
	// employment_income: null;
	employment_income_employer: string | null;
	employment_income_occupation: string | null;
	employment_income_gross_annual_salary: string | null;
	// other_bank_currency: null;
	cryptocurrency: string | null;
	cryptocurrency_chain: string | null;
	cryptocurrency_wallet_address: string | null;
	created_at: string;
	updated_at: string;
}

export interface IApiGetOneUserResponse {
	id: number;
	oneify_id: number;
	email: string;
	email_confirmed: 0 | 1;
	// password: string; // what for?
	// google2fa_secret: null; // what for?
	google2fa_enabled: 0 | 1;
	user_data_approved: 0 | 1;
	last_login: string;
	blocked: string;
	status_id: ApiUserStatus;
	last_activity: string;
	type: string;
	// invite_key: string; // what for?
	// remember_token: null; // what for?
	data: IApiGetOneUserData;
	documents: Array<string>;
}

export interface IDocument {
	created_at: string;
	file: string;
	id: number;
	name: string;
	path: string;
	updated_at: string;
	user_id: number;
}

export interface IUserWalletsItem {
	type: ECoinType;
	id: number;
	balance: number;
	balance_eur: number;
	frozen_balance: string;
	tag: string;
	has_withdrawal_tag: boolean;
	total: string;
	btc_value: string;
	usd_value: string;
	asset: IWalletAsset;
	address: Array<IAddressItem>;
}

export interface IAddressItem {
	address: string;
	segwit_address: string;
	asset_id: number;
	balance_id: number;
	chain_id: number;
	fireblocksBalanceInEur: number;
	fireblocks_balance: number;
	id: number;
	nickname: string;
	tag: any;
	type: string;
}

export interface IWalletAsset {
	id: number;
	code: string;
	type: string;
	depositable?: 0 | 1;
	withdrawable?: 0 | 1;
	exchangeable?: 0 | 1;
	depositable_message: null | string;
	withdrawable_message: null | string;
	decimals: null | number;
	name: string;
	deposit_max: number;
	deposit_min: number;
	withdraw_max: number;
	withdraw_min: number;
	exchange_min: number;
	img_path: string;
	chains?: IWalletChains[];
}

export interface IWalletChains {
	id: number;
	name: string;
	code: string;
	pivot: IPivot;
	balances: IBalances;
}

export interface IBalances {
	balance: number;
	balance_eur: number;
}

export interface IPivot {
	asset_id: number;
	chain_id: number;
}

export interface IReferralItem {
	id: number;
	email: string;
	data: {
		first_name: string;
		last_name: string;
	};
}

export interface IUserReferralRequest {
	userId: number | string;
	per_page?: number;
	current_page?: number;
}

export interface IDeleteUserReferalRequest {
	userId: string;
	referral_id: number;
}

export interface IDeleteUserReferalRequestPayload extends IDeleteUserReferalRequest {
	userId: string;
}

export type IUserReferralResponse = IReferralItem[];

export type IUserWalletsResponse = {
	fiat: {
		btc: number;
		eur: number;
	};
	crypto: {
		btc: number;
		eur: number;
	};
	wallets: IUserWalletsItem[];
};

export enum EApiUserStatus {
	APPROVED = 'approved',
	PENDING = 'pending',
	UNVERIFIED = 'unverified',
	REJECTED = 'rejected',
}

export interface IUsersFiltersOption<TOptionValue> {
	id: number | string;
	name: string;
	value: TOptionValue;
}

export interface ISecretCodeResponse {
	secret: string;
}

export type TApiUsersStatus = 'approved' | 'pending' | 'unverified' | 'rejected';

export type TUsersFiltersStatusOption = IUsersFiltersOption<TApiUsersStatus | undefined>;

// export type ITradingLimitsResponse = IUserTradingLimitsItem[];

export interface IUserFireBlockIdPayload {
	userId: number;
	data: { fireblocks_vault_id: number; totp: string };
	closeModal: () => void;
}
