import { IAsset, IChain } from 'redux/reducers/currency/types';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';
import {
	IAddDepositCryptoRequest,
	IAddTradeRequest,
	IAddWithdrawalCryptoRequest,
	IAddWithdrawalFiatRequest,
	IGetTradeLimitPayload,
} from 'redux/reducers/transactions/types';
import { IUser } from 'redux/reducers/auth/types';
import { AxiosResponse } from 'axios';
import { IApiPagination } from '../types';
import { IAddressItem } from '../users/types';

export interface ITransactionsApi {
	// new
	putPendingDepositNote: (payload: IPutCryptoNotePayload) => Promise<AxiosResponse>;
	putPendingDepositApprove: (payload: IPutPendingDepositPayloadRequest) => Promise<AxiosResponse>;
	putPendingDepositReject: (payload: IPutPendingDepositPayloadRequest) => Promise<AxiosResponse>;
	putPendingDepositCryptoProcessing: (
		payload: IPutPendingDepositPayloadRequest,
	) => Promise<AxiosResponse>;
	getPendingDeposit: (params: IGetPendingDepositPayloadRequest) => Promise<TPendingDepositResponse>;
	putPendingWithdrawalCryptoApprove: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalCryptoReject: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalStatus: any;
	getAllBankAccount: () => Promise<any>;
	// Manual transactions
	postAddDepositCrypto: (payload: IAddDepositCryptoRequest) => Promise<AxiosResponse>;
	postAddWithdrawalFiat: (payload: IAddWithdrawalFiatRequest) => Promise<AxiosResponse>;
	postAddWithdrawalCrypto: (payload: IAddWithdrawalCryptoRequest) => Promise<AxiosResponse>;
	postAddTrade: (payload: IAddTradeRequest) => Promise<AxiosResponse>;
	//
	getCryptoHistory: (
		params: IApiGetTransactionHistoryParams,
	) => Promise<TApiGetCryptoHistoryResponse>;
	getFiatHistory: (params: IApiGetTransactionHistoryParams) => Promise<TApiGetFiatHistoryResponse>;
	getTradeHistory: (
		params: IApiGetTransactionHistoryParams,
	) => Promise<TApiGetTradeHistoryResponse>;
	getCryptoTransfers: any;
	getFiatTransfers: any;
	// getCryptoDeposits: () => Promise<TCryptoDepositsResponse>;
	getCryptoDeposits: any;
	// getFiatDeposits: () => Promise<TCryptoDepositsResponse>;
	getFiatDeposits: any;
	postFiatDeposits: (params: IApiPostFiatDepositParams) => Promise<AxiosResponse>;
	getCryptoTransactions: (
		params: IApiGetTransactionParams,
	) => Promise<TApiGetCryptoTransactionsResponse>;
	getFiatTransactions: (
		params: IApiGetTransactionParams,
	) => Promise<TApiGetFiatTransactionsResponse>;
	putFiatTransactions: (
		params: IApiPutFiatTransactionsPayload,
		type: EApiTransactionType,
		id: number,
	) => Promise<TApiGetFiatTransactionsResponse>;
	putCryptoWithdrawalApprove: (id: number) => Promise<AxiosResponse>;
	putCryptoWithdrawalReject: (id: number) => Promise<AxiosResponse>;
	putCryptoNote: (payload: IPutCryptoNotePayload) => Promise<AxiosResponse>;
	getPooledDeposits: (params: IApiGetTransactionParams) => Promise<IApiPooledDeposit>;
	getPooledDepositsFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	getCryptoWithdrawalLimits: () => Promise<TWithdrawalLimitResponse>;
	getFiatWithdrawalLimits: () => Promise<TWithdrawalLimitResponse>;
	putWithdrawalLimits: (payload: IApiPutWithdrawalLimitPayload) => Promise<AxiosResponse>;
	getGeneralWithdrawalLimit: () => Promise<IGeneralWithdrawalLimit>;
	putGeneralWithdrawalLimit: (payload: IPutGeneralWithdrawalLimitPayload) => Promise<AxiosResponse>;
	getTrades: (params: IApiGetTransactionParams) => Promise<TTradesResponse>;
	getTradesFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	getTradingLimitsCrypto: (params: IGetTradeLimitPayload) => Promise<ITradeLimitsResponse>;
	getTradingLimitsFiat: (params: IGetTradeLimitPayload) => Promise<ITradeLimitsResponse>;
	putTradingLimit: (params: any) => Promise<AxiosResponse>;
	getCryptoTransactionFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	getFiatTransactionFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	getProfitWallets: (params: IApiGetTransactionParams) => Promise<TProfitWalletResponse>;
	getProfitWalletsFile: (params: IGetFilePayload) => Promise<AxiosResponse>;
	getPendingWithdrawal: (
		params: IGetPendingWithdrawalPayloadRequest,
	) => Promise<TPendingWithdrawalResponse>;
	getPendingWithdrawalFile: (
		params: IPendingWithdrawalFilePayloadRequest,
	) => Promise<AxiosResponse>;
	putPendingWithdrawalApprove: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalReject: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalCancel: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalCryptoProcessing: (id: number) => Promise<AxiosResponse>;
	putPendingWithdrawalNote: (payload: IPutCryptoNotePayload) => Promise<AxiosResponse>;
	getLedgerTransactions: (params: IApiGetLedgerTransactionParams) => Promise<TLedgerResponse>;
}

// 📌 Common

export type TApiTransactionType = 'deposit' | 'withdrawal';

export type TApiTransactionStatus =
	| 'completed'
	| 'failed'
	| 'pending'
	| 'admin_pending'
	| 'pending_signing';

export type TApiTransactionAssetType = 'crypto' | 'fiat' | 'token';

// 📌 File download

export interface IGetFilePayload {
	token?: string;
	fileType: 'csv' | 'xlsx';
	type?: EApiTransactionType;
	adminId?: number;
}

export type TTradesResponse = IApiPagination<ITrade>;

// 📌 Transaction history

export interface IApiPostFiatDepositParams {
	user_id: string;
	date: string;
	asset_id: string;
	indicated_amount: string;
	fee: string;
	net_fee: number;
	amount: string;
	status: string;
	note: string;
	totp: string;
	bank_account_id: string;
	admin_requisite_id: string;
	corrections?: boolean | number;
}

// 📌 Transaction history

export interface IApiGetTransactionHistoryParams {
	userId: number | string;
	current_page?: number;
	per_page?: number;
	type?: TApiTransactionType;
	asset_id?: string;
	status?: TApiTransactionStatus;
	date_from?: string;
	date_to?: string;
	id?: number;
}

// 📌 Transaction history - Fiat

export interface IApiTransactionHistoryAsset {
	id: number;
	code: string;
	type: TApiTransactionAssetType;
	name?: string;
}

export interface IApiTransactionHistoryChain {
	id: number;
	name: string;
	code: string;
}

export interface IApiFiatHistoryBankAccount {
	id: number;
	user_id: number;
	bank_nickname: string;
	account_number: string;
	bank_name: string;
	beneficiary_name?: string;
	beneficiary_address?: string;
	beneficiary_country?: string;
	swift_bic?: string;
	bank_address?: string;
	bank_country?: string;
	bank_currency?: string;
	note?: string;
}

export interface IApiFiatHistoryAdminRequisite {
	id: number;
	bank_nickname: string;
	account_number: string;
	bank_name: string;
}

export interface IApiCryptoHistoryItem {
	corrections: boolean;
	user: IUser;
	description?: string;
	admin_notes?: string;
	client_notes?: string;
	id: number;
	user_id: number;
	asset_id: number;
	chain_id: number;
	address_id: number | null;
	amount: number;
	asset: IApiTransactionHistoryAsset;
	chain: IApiTransactionHistoryChain;
	destination: string;
	tx_id: string;
	type: TApiTransactionType;
	status: TApiTransactionStatus;
	created_at: string;
	updated_at: string;
	gross_fee: string;
	net_fee: string;
	tx_hash: string;
	ext_tx_hash: string;
	fee: number;
	reference: string;
	instructions: string;
	files: any;
	note: string | null;
	address: IAddressItem;
	// asset: IApiTransactionHistoryAsset;
	// chain: IApiTransactionHistoryChain;
}

export type TApiGetCryptoHistoryResponse = IApiPagination<IApiCryptoHistoryItem>;
export type TCryptoDepositsResponse = IApiCryptoHistoryItem[];

// 📌 Transaction history - fiat

export interface IApiFiatHistoryItem {
	user: IUser;
	description?: string;
	admin_notes?: string;
	client_notes?: string;
	destination?: string;
	note?: string;
	id: number;
	user_id: number;
	asset_id: number;
	bank_account_id: number | null;
	admin_requisite_id: number;
	amount: number;
	fee: number;
	indicated_amount: number;
	type: TApiTransactionType;
	status: TApiTransactionStatus;
	// note: null;
	created_at: string;
	updated_at: string;

	asset: IApiTransactionHistoryAsset;
	bank_account: IApiFiatHistoryBankAccount | null;
	admin_requisite: IApiFiatHistoryAdminRequisite;
	gross_fee: number;
	net_fee: number;
	reference: string;
	instructions: string;
	files: any;
}

export type TApiGetFiatHistoryResponse = IApiPagination<IApiFiatHistoryItem>;

// 📌 Transaction history - trade

export interface IApiTradeHistoryItem {
	id: number;
	pair: string;
	rate: number;
	fee: number;
	amount: number;
	quantity: number;
	status: string;
	created_at: string;
	side: string;
	from_asset_id: number;
	to_asset_id: number;
	price_b2c2: string;
	user_id: number;
}
// 📌 Transaction

export enum EApiTransactionType {
	DEPOSIT = 'deposit',
	WITHDRAWAL = 'withdrawal',
	TRANSFER = 'transfer',
	TRADE = 'trade',
	CRYPTO_POOLED = 'crypto_pooled',
}
export enum EApiTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	COMPLETED = 'completed',
	PENDING = 'pending',
	PENDING_SIGNING = 'pending_signing',
	FAILED = 'failed',
	CANCELED = 'cancelled',
	REJECTED = 'rejected',
	SUCCESS = 'success',
	BROADCASTING = 'broadcasting',
	PROCESSING = 'processing',
}

export interface IApiGetTransactionParams {
	per_page?: number;
	current_page?: number;
	type?: EApiTransactionType;
	status?: string;
	date_to?: string;
	date_from?: string;
	id?: number;
}

// 📌 Transaction - crypto

export enum EApiCryptoTransactionStatus {
	PENDING = 'pending',
	PENDING_SIGNING = 'pending_signing',
	ADMIN_PENDING = 'admin_pending',
	COMPLETED = 'completed',
	FAILED = 'failed',
	CANCELED = 'canceled',
	BROADCASTING = 'broadcasting',
	REJECTED = 'rejected',
	PROCESSING = 'processing',
}

export enum EApiCryptoTransactionDestination {
	DESTINATION = 'destination',
	TX_HASH = 'tx_hash',
}

export interface IApiCryptoTransaction {
	id: number;
	user_id: number;
	asset_id: number;
	chain_id: number;
	address_id: number;
	amount: number;
	fee: number;
	destination: string;
	tag: string;
	tx_id: string;
	tx_hash: string;
	type: EApiTransactionType;
	status: EApiCryptoTransactionStatus;
	created_at: string;
	updated_at: string;
	asset: IAsset;
	chain: IChain;
	user: IUser;
	note: string;
	gross_fee: number;
	net_fee: number;
	address: IAddressItem;
	record_type: string;
}
export type TApiGetCryptoTransactionsResponse = IApiPagination<IApiCryptoTransaction>;
export type TApiGetTradeHistoryResponse = IApiPagination<IApiTradeHistoryItem>;
export type TApiTransactionHistoryStatus =
	| 'completed'
	| 'failed'
	| 'pending'
	| 'admin_pending'
	| 'cancelled'
	| 'broadcasting'
	| 'rejected'
	| 'pending_signing'
	| 'processing';

export enum ECryptoSortedFields {
	GROSS = 'gross_fee',
	NET = 'net_fee',
	FEE = 'fee',
}

export interface IPutCryptoNotePayload {
	note: string;
	transaction_id: number;
}

export interface IPutCryptoNotePayloadRequest {
	type: EApiTransactionType | EPendingWithdrawalType;
	data: IPutCryptoNotePayload;
}

// 📌 Transaction - fiat

export enum EApiFiatTransactionStatus {
	PENDING = 'pending',
	COMPLETED = 'completed',
	FAILED = 'failed',
	CANCELED = 'cancelled',
	REJECTED = 'rejected',
	PROCESSING = 'processing',
}

export interface IAdminRequisite {
	beneficiary_country?: string;
	bank_country?: string;
	id: number;
	note: string;
	swift_bic: string;
	bank_name: string;
	bank_address: string;
	bank_nickname: string;
	account_number: string;
	beneficiary_name: string;
	beneficiary_address: string;
	created_at: string;
	updated_at: string;
}

export interface IApiFiatTransaction {
	id: number;
	bank: IBankAccount;
	user_id: number;
	asset_id: number;
	bank_account_id: number;
	bank_account: IBankAccount;
	bank_name: string;
	admin_requisite_id: number;
	admin_requisite: IAdminRequisite;
	amount: number;
	fee: number;
	indicated_amount: number;
	type: EApiTransactionType;
	status: EApiFiatTransactionStatus;
	created_at: string;
	updated_at: string;
	asset: IAsset;
	user: IUser;
	note: string;
	gross_fee: number;
	net_fee: number;
}

export interface IApiPutFiatTransactionsPayload {
	date: string;
	fee?: number;
	note?: string;
	status?: string;
	indicated_amount?: number;
	admin_requisite_id: number;
}

export interface IPutFiatTransactionsPayload {
	data: IApiPutFiatTransactionsPayload;
	type: EApiTransactionType;
	id: number;
}

export enum EFiatSortedFields {
	GROSS = 'gross_fee',
	NET = 'net_fee',
	FEE = 'fee',
}

export type TApiGetFiatTransactionsResponse = IApiPagination<IApiFiatTransaction>;

// 📌 Transaction - Pooled deposit

export enum EApiPooledDepositTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	PENDING_SIGNING = 'pending_signing',
	COMPLETED = 'completed',
	FAILED = 'failed',
	REJECTED = 'rejected',
	CANCELED = 'cancelled',
	BROADCASTING = 'broadcasting',
	PROCESSING = 'processing',
}
export enum EApiProfitWalletTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	COMPLETED = 'completed',
	FAILED = 'failed',
	CANCELED = 'cancelled',
	BROADCASTING = 'broadcasting',
	REJECTED = 'rejected',
}
export enum EApiPendingWithrawalsTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	PENDING = 'pending',
	COMPLETED = 'completed',
	FAILED = 'failed',
	CANCELED = 'cancelled',
	BROADCASTING = 'broadcasting',
	PENDING_SIGNING = 'pending_signing',
	PROCESSING = 'processing',
}
export enum EApiPendingDepositsCryptoTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	BROADCASTING = 'broadcasting',
	PROCESSING = 'processing',
}
export enum EApiPendingDepositsFiatTransactionStatus {
	ADMIN_PENDING = 'admin_pending',
	PENDING = 'pending',
	PROCESSING = 'processing',
}

export interface IApiPooledDeposit {
	id: number;
	user_id: number;
	asset_id: number;
	chain_id: number;
	type: string;
	amount: number;
	tag: string;
	tx_id: string;
	transaction_id?: number;
	tx_hash: string;
	status: EApiPooledDepositTransactionStatus;
	created_at: string;
	updated_at: string;
	user: IUser;
	destination?: string;
}

export enum EPooledDepositSortedFields {
	AMOUNT = 'amount',
}

export type TGetPooledDepositsResponse = IApiPagination<IApiPooledDeposit>;

// 📌 Transaction - Withdrawal Limits

export enum EWithdrawalLimitType {
	CRYPTO = 'crypto',
	FIAT = 'fiat',
}

export interface IApiWithdrawalLimit {
	id: number;
	asset_id: number;
	minimum: number;
	maximum: number;
	hourly: number;
	daily: number;
	created_at: string;
	updated_at: string;
	asset: IAsset;
	perm: boolean;
}

export interface IApiPutWithdrawalLimitPayload {
	type: EWithdrawalLimitType;
	witdraw_limit_id: number;
	data: {
		maximum: number;
		minimum: number;
		hourly: number;
		daily: number;
	};
}

export interface IGeneralWithdrawalLimit {
	id: number;
	key: string;
	value: number;
	created_at: string;
	updated_at: string;
}

export interface IPutGeneralWithdrawalLimitPayload {
	withdraw_limit_total_eur: number;
}

export type TWithdrawalLimitResponse = IApiPagination<IApiWithdrawalLimit>;

// 📌 Trade

export enum ETradeSide {
	BUY = 'buy',
	SELL = 'sell',
}

export interface ITrade {
	id: number;
	user_id: number;
	uuid: string;
	pair: string;
	rate: number;
	quantity: number;
	fee: number;
	amount: number;
	side: ETradeSide;
	from_asset_id: number;
	to_asset_id: number;
	created_at: string;
	updated_at: string;
	user: IUser;
	liquidity_provider: string;
	liquidity_provider_id: string;
	ExternalUrl: string;
	price_b2c2: string;
}
export interface ITradeLimitsResponse {
	current_page: number;
	next_page_url: string;
	data: Array<ITradeLimit>;
	path: string;
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
}

export interface ITradeLimit {
	id: number;
	user_id: number;
	asset_id: number;
	limit_min: number;
	limit_max: number;
	created_at: string;
	updated_at: string;
	permission?: boolean;
	updateHandler: () => void;
	type?: EWithdrawalLimitType;
}

export interface IPutTradeLimitPayload {
	trading_limit_id: number;
	limit_max: number;
	limit_min: number;
	onSuccess: () => any;
}

export enum ETradeSortedFields {
	QUANTITY = 'quantity',
	AMOUNT = 'amount',
	RATE = 'rate',
	FEE = 'fee',
}

// 📌 Profit Wallets

export interface IProfitWallet {
	id: number;
	admin_id?: number | any;
	asset_id: number;
	chain_id: number | null;
	type: string;
	amount: number;
	tag: string | null;
	tx_id: string;
	tx_hash: string | null;
	status: EApiPooledDepositTransactionStatus;
	created_at: string;
	updated_at: string;
	destination: string;
	asset: IAsset;
	chain: IChain | null;
	user: IUser;
	transaction_id?: number;
}

export type TProfitWalletResponse = IApiPagination<IProfitWallet>;

// 📌 Pending Withdrawal

export enum EPendingWithdrawalType {
	CRYPTO = 'crypto',
	FIAT = 'fiat',
}

export enum EPendingDepositsType {
	CRYPTO = 'crypto',
	FIAT = 'fiat',
}

export interface IPendingDeposit {
	id: number;
	asset_id: number;
	chain_id: number | null;
	type: string;
	amount: number;
	tag: string | null;
	tx_id: string;
	tx_hash: string | null;
	status: EApiTransactionStatus;
	created_at: string;
	updated_at: string;
	asset: IAsset;
	chain: IChain | null;
	user: IUser;
	note: string | null;
	user_id: number;
}

export interface IPendingWithdrawal {
	id: number;
	asset_id: number;
	chain_id: number | null;
	type: string;
	amount: number;
	tag: string | null;
	tx_id: string;
	tx_hash: string | null;
	status: EApiTransactionStatus;
	created_at: string;
	updated_at: string;
	asset: IAsset;
	chain: IChain | null;
	user: IUser;
	note: string | null;
	user_id: number;
}
export enum EPendingWithdrawalFields {
	AMOUNT = 'amount',
	TYPE = 'type',
	GROSS = 'gross_fee',
	NET = 'net_fee',
	FEE = 'fee',
}

export interface IGetPendingDepositPayloadRequest {
	type: 'fiat' | 'crypto';
	params: IApiGetTransactionParams;
}

export interface IGetPendingWithdrawalPayloadRequest {
	type: 'fiat' | 'crypto';
	params: IApiGetTransactionParams;
}

export interface IPutPendingWithdrawalPayloadRequest {
	type: 'fiat' | 'crypto';
	id: number;
}

export interface IPutPendingDepositPayloadRequest {
	type: 'fiat' | 'crypto';
	id: number;
	gross_fee?: number;
	fee?: number;
}

export interface IPendingWithdrawalFilePayloadRequest extends IGetFilePayload {
	assetType: 'fiat' | 'crypto';
}

export type TPendingWithdrawalResponse = IApiPagination<IPendingWithdrawal>;

export type TPendingDepositResponse = IApiPagination<IPendingDeposit>;

// Ledger

export interface ILedger {
	completed_at: string;
	ext_tx_hash: string;
	created_at: string;
	updated_at: string;
	user_id: number;
	oneify_id?: string;
	type: string;
	currency: string;
	credit: number | null;
	debit: number | null;
	fee: number;
	net: number;
	balance: number;
	reference: string | null;
	tx_id: number;
	destination: string;
	status_id: number;
	credit_fee: number | null;
	debit_fee: number | null;
}

export type TLedgerResponse = IApiPagination<ILedger>;

export interface IApiGetLedgerTransactionParams {
	per_page?: number;
	current_page?: number;
	type?: EApiTransactionType;
	currency?: string;
	date_to?: string;
	date_from?: string;
	user_id?: number;
}
