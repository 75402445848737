/* eslint-disable @typescript-eslint/naming-convention */

import { FC, useLayoutEffect, useState } from 'react';
import {
	IB2c2Balance,
	IDepositBalances,
	ILiquidityItem,
	IPoolBalances,
	IProfitBalanceItem,
	IWithdrawalBalanceItem,
} from 'redux/reducers/dashboard/types';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { useSelector } from 'react-redux';
import BalanceList from './BalanceList/BalanceList';
import BalanceChart from './BalanceChart/BalanceChart';
import { EStatType, IBalanceBlockProps } from './types';
import { data1 } from './testData';
import Loading from '../../../../layouts-elements/Loading/Loading';
import { ICurrencyDataItem } from '../../../../redux/reducers/currency/types';
import { IBalanceListPropsList } from './BalanceList/types';

const StatBlock: FC<IBalanceBlockProps> = ({ title, data, type }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [items, setItems] = useState<IBalanceListPropsList[]>();
	const [total, setTotal] = useState('');
	const currencies = useSelector(getCurrencyData);

	function getChainCodeById(assets: ICurrencyDataItem[], chainId: number) {
		// eslint-disable-next-line no-restricted-syntax
		for (const asset of assets) {
			const chain = asset.chains.find((item) => item.id === chainId);
			if (chain) {
				return chain.code;
			}
		}
		return null; // Return null if the chain ID is not found
	}

	useLayoutEffect(() => {
		if (data && currencies) {
			switch (type) {
				case EStatType.LIQUIDITY: {
					const liquid = data.liquidity.data.map((item: ILiquidityItem) => {
						const img = currencies.find((curr) => curr.code === item.code)?.img_path;
						return {
							name: item.code.toUpperCase(),
							valueCrypto: item.valueCrypto ? item.valueCrypto : 0,
							valueEuro: item.valueEuro ? item.valueEuro : 0,
							img,
						};
					});
					const sumLiquidity =
						liquid.reduce((acc: number, item: ILiquidityItem) => {
							// const { valueEuro } = item;
							if (item && item.valueEuro) {
								return acc + item.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(liquid);
					setTotal(sumLiquidity);
					break;
				}
				case EStatType.PLATFORM_BALANCES: {
					const balances = data.map((item: ILiquidityItem) => {
						const img = currencies.find((curr) => curr.code === item.name.toLowerCase())?.img_path;
						return {
							name: item.name.toUpperCase(),
							valueCrypto: item.valueCrypto ? item.valueCrypto : 0,
							valueEuro: item.valueEuro ? item.valueEuro : 0,
							img,
						};
					});
					const sumPlatform =
						balances.reduce((acc: number, item: ILiquidityItem) => {
							// const { valueEuro } = item;
							if (item && item.valueEuro) {
								return acc + item.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(balances);
					setTotal(sumPlatform);
					break;
				}
				case EStatType.B2C2_BALANCES: {
					const sumB2C2 =
						data?.data.reduce((acc: number, item: IB2c2Balance) => {
							// const { b2c2 } = item;
							// const { valueEuro } = b2c2;
							if (item && item.b2c2 && item.b2c2.valueEuro) {
								return acc + item.b2c2.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(
						data?.data.map((item: IB2c2Balance) => {
							return { ...item.b2c2, img: item.img_path };
						}),
					);
					setTotal(sumB2C2);
					break;
				}
				case EStatType.DEPOSIT_BALANCES: {
					const sumDep =
						data?.data.reduce((acc: number, item: IDepositBalances) => {
							// const { valueEuro } = item;
							if (item && item.valueEuro) {
								return acc + Number(item.valueEuro);
							}
							return acc;
						}, 0) || 0;
					const newArr: IDepositBalances[] = [];
					data.data.forEach((el: IDepositBalances) => {
						// const dublIdx = newArr.findIndex((item: IDepositBalances) => item.code === el.code);
						// if (dublIdx === -1) {
						// 	newArr.push(el);
						// } else {
						// 	Number(newArr[dublIdx].valueEuro) + Number(el.valueEuro);
						// }
						newArr.push(el);
					});

					setItems(
						newArr.map((item: IDepositBalances) => {
							const img = currencies.find((curr) => curr.code === item.code)?.img_path;
							return {
								name: item.code.toUpperCase(),
								chain: getChainCodeById(currencies, item.chain_id),
								valueCrypto: item.valueCrypto ? item.valueCrypto : 0,
								valueEuro: item.valueEuro ? item.valueEuro : 0,
								img,
							};
						}),
					);
					setTotal(sumDep);
					break;
				}
				case EStatType.PENDING_FEES: {
					const arr: IProfitBalanceItem[] = Object.values(data) || [];
					const sumFee =
						arr.reduce((acc: number, item: IProfitBalanceItem) => {
							// const { valueEuro } = item;
							if (item && item.valueEuro) {
								return acc + item.valueEuro;
							}
							return acc;
						}, 0) || 0;
					if (arr.length) {
						setItems(
							arr.map((item: IProfitBalanceItem) => {
								const img = currencies.find(
									(curr) => curr.code === item.name.toLowerCase(),
								)?.img_path;
								return { ...item, img };
							}),
						);
					}
					setTotal(String(sumFee));
					break;
				}

				case EStatType.POOL_BALANCES: {
					const sumPool =
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						data.data.reduce((acc: number, item: IPoolBalances) => {
							// const { pool } = item;
							// const { valueEuro } = pool;
							if (item && item.pool && item.pool.valueEuro) {
								return acc + item.pool.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(
						data?.data.map((item: IPoolBalances) => {
							return { ...item.pool, img: item.img_path };
						}),
					);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					setTotal(sumPool);
					break;
				}
				case EStatType.PROFIT_BALANCES: {
					const sum =
						data?.reduce((acc: number, item: IProfitBalanceItem) => {
							// const { valueEuro } = item;
							if (item && item.valueEuro) {
								return acc + item.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(
						data.map((item: IProfitBalanceItem) => {
							const img = currencies.find(
								(curr) => curr.code === item.name.toLowerCase(),
							)?.img_path;
							return {
								...item,
								img,
								chain: item.chain_id ? getChainCodeById(currencies, item.chain_id) : null,
							};
						}),
					);
					setTotal(sum);
					break;
				}
				case EStatType.WITHDRAW_BALANCES: {
					const sumWithdraw =
						data?.data.reduce((acc: number, item: IWithdrawalBalanceItem) => {
							// const { values } = item;
							// const { valueEuro } = values;
							if (item && item.values && item.values.valueEuro) {
								return acc + item.values.valueEuro;
							}
							return acc;
						}, 0) || 0;
					setItems(
						data?.data.map((item: IWithdrawalBalanceItem) => {
							return {
								...item.values,
								img: item.asset.img_path,
								chain: item.values.chain_id
									? getChainCodeById(currencies, item.values.chain_id)
									: null,
							};
						}),
					);
					setTotal(sumWithdraw);
					break;
				}
				default:
			}
		}
	}, [data, type, currencies]);
	return (
		<div className="balance-info admin-balance-block">
			<div className="title-info">
				<div className="title-info_left">
					<p> {title}</p>
				</div>
				<div className="title-info_right">
					<p className="info">Total Balance: </p>{' '}
					<p className="results">
						{total.length === 0 ? (
							<Loading height={25} />
						) : (
							<>{Number(total).toLocaleString('en-US', { maximumFractionDigits: 2 })} EUR</>
						)}
					</p>
				</div>
			</div>
			<div className="title-block">
				{items && items !== undefined ? (
					<>
						<div className="balance-info-date">
							<BalanceList list={items} activeIndex={activeIndex} onChangeActive={setActiveIndex} />
						</div>
						<BalanceChart list={items} activeIndex={activeIndex} />
					</>
				) : (
					<Loading height={40} />
				)}
			</div>
		</div>
	);
};

export default StatBlock;
