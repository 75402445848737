export const toFixedNumber = (num: any, curr: any, isFormatted?: boolean, decimals?: number) => {
	const cryptoCurrency = ['BTC', 'LTC', 'BCH', 'ETH', 'TRX', 'XRP', 'crypto'];
	let isCrypto = false;
	let finalNumber = '';
	cryptoCurrency.forEach((e) => {
		if (e.toLowerCase() === curr.toLowerCase()) {
			isCrypto = true;
		}
	});
	if (isCrypto) {
		const decimalPlaces = decimals ?? 5;
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,prefer-regex-literals
		const re = new RegExp(`^-?\\d+(?:.\\d{0,${decimalPlaces}})?`);
		const match = num.toString().match(re);
		if (!match) {
			// If no match, just return '0' with required decimals
			finalNumber = `0.${'0'.repeat(decimalPlaces)}`;
		} else {
			// eslint-disable-next-line prefer-destructuring
			finalNumber = match[0];
			const parts = finalNumber.split('.');
			const beforeDot = parts[0];
			const afterDot = parts[1] ?? '';
			if (!finalNumber.includes('.')) {
				finalNumber += `.${'0'.repeat(decimalPlaces)}`;
			} else if (afterDot.length < decimalPlaces) {
				finalNumber += '0'.repeat(decimalPlaces - afterDot.length);
			}
			const [finalBefore, finalAfter] = finalNumber.split('.');
			if (isFormatted) {
				return `${Number(finalBefore).toLocaleString('en-US')}.${finalAfter}`;
			}
			return `${Number(finalBefore)}.${finalAfter}`;
		}
	}
	// For non-crypto
	const decimalPlaces = decimals ?? 2;
	// eslint-disable-next-line prefer-regex-literals
	const re = new RegExp(`^-?\\d+(?:.\\d{0,${decimalPlaces}})?`);
	const match = num.toString().match(re);
	if (!match) {
		// If no match, just return '0' with required decimals
		finalNumber = `0.${'0'.repeat(decimalPlaces)}`;
	} else {
		// eslint-disable-next-line prefer-destructuring
		finalNumber = match[0];
		const parts = finalNumber.split('.');
		const beforeDot = parts[0];
		const afterDot = parts[1] ?? '';
		const beforeDotFormatted = Number(beforeDot).toLocaleString('en-US');
		if (!finalNumber.includes('.')) {
			finalNumber += `.${'0'.repeat(decimalPlaces)}`;
			const dotsAfter = `${'0'.repeat(decimalPlaces)}`;
			if (isFormatted) {
				return `${Number(finalNumber).toLocaleString('en-US')}.${dotsAfter}`;
			}
			return finalNumber;
		}
		if (afterDot.length < decimalPlaces) {
			finalNumber += '0'.repeat(decimalPlaces - afterDot.length);
		}
		if (isFormatted) {
			const [finalBefore, finalAfter] = finalNumber.split('.');
			return `${Number(finalBefore).toLocaleString('en-US')}.${finalAfter}`;
		}
	}
	return finalNumber;
};
