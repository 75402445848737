import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { popUpOpen, setPopUpData } from '../../../../../redux/reducers/popUp/reducer';
import { getCryptoCurrencyData } from '../../../../../redux/reducers/currency/selectors';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
	permission: boolean;
}

const Item: FC<Props> = ({ item, permission }) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { code, total_debit, asset_id, chain_id, valueCrypto } = item;
	const dispatch = useDispatch();
	const currency = useSelector(getCryptoCurrencyData);
	const [chainCode, setChainCode] = useState<any>();
	const [coinName, setCoinName] = useState<any>();

	// useLayoutEffect(() => {
	// 	console.log(item);
	// }, [item]);

	useLayoutEffect(() => {
		const currentCurr = currency.find((el) => {
			return el.id === asset_id;
		});
		const currentChain = currentCurr?.chains.find((el) => {
			return el.id === chain_id;
		});
		setChainCode(currentChain?.code);
		setCoinName(currentCurr?.name);
		// console.log(currentCurr?.name);
	}, [asset_id, chain_id, currency]);

	const handleProfitWalletSend = () => {
		const data = {
			title: 'Send to Pool Wallet',
			currency:
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				code === 'eth' ? `${code.toUpperCase()} (${chainCode})` : code.toUpperCase(),
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			depositAmount: `${roundingNumber(valueCrypto, code)} ${code.toUpperCase()}`,
			walletName: 'Pool Wallet',
			payload: {
				type: 'Deposit wallets crypto',
				asset_id: item.asset_id,
				chain_id: item.chain_id,
			},
			total_balance: valueCrypto,
		};
		dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
		dispatch(setPopUpData(data));
	};
	if (code === 'eth' && chainCode === 'Rinkeby') {
		return null;
	}

	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Cryptocurrency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						{code === 'usdt' ? (
							<p>
								{code.toUpperCase()} ({chainCode})
							</p>
						) : (
							<p>{code.toUpperCase()}</p>
						)}
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Deposit Wallets</p>
					<div className="td__wrap">
						<p>{roundingNumber(valueCrypto, code)}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name" />
					{permission && (
						<button
							onClick={handleProfitWalletSend}
							className="btn btn-primary btn--full"
							type="button"
						>
							Send to Pool Wallet
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default Item;
