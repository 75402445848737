import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import {
	INIT_FILTER_OPTIONS,
	STATUS_POOLED_DEPOSIT_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EPooledDepositSortedFields } from 'services/api/transactions/types';
import { ITransactionsTab } from '../../types';

const PooledDepositHeader: FC<ITransactionsTab> = ({ setSorted }) => {
	const dispatch = useDispatch();

	const [sortedAmount, setSortedAmount] = useState<TSortedDirection>('');
	const onSortedAmount = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EPooledDepositSortedFields.AMOUNT, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedAmount(direction);
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.ASSET_ID,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_POOLED_DEPOSITS,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.STATUS,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_POOLED_DEPOSITS,
			}),
		);
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_POOLED_DEPOSITS,
			}),
		);
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					{/* <FilterSelect
						title="Cryptocurrency"
						options={
							[
								...INIT_FILTER_OPTIONS,
								...cryptoCurrencyFilterOptions,
							] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleAssetChange}
					/> */}
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<SortedButton direction={sortedAmount} title="Amount" handleSort={onSortedAmount} />
			</div>
			<div className="td">
				<div className="td-name">
					<p>Destination</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx Hash</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>TxID</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name">
					{/* <FilterSelect
						title="Status"
						options={
							[
								...INIT_FILTER_OPTIONS,
								...STATUS_POOLED_DEPOSIT_FILTER_OPTIONS,
							] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleStatusChange}
					/> */}
					<p>Status</p>
				</div>
			</div>
		</div>
	);
};

export default PooledDepositHeader;
