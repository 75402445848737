import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { roundingNumber } from 'services/utils/roundingNumber';
import { ETradeSide, ITrade } from 'services/api/transactions/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment/moment';
import { shortNotation } from '../../../../services/utils/strings';
import IconSvg from '../../../../ui/Svg/IconSvg';
import { notificationContainer } from '../../../../services/utils/notificationContainer';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const ReferralsTradeTableRow: FC<any> = ({
	created_at,
	user_id,
	pair,
	side,
	rate,
	quantity,
	amount,
	fee,
	uuid,
	user,
	referral_fee,
	referral_percent,
	price_b2c2,
}) => {
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [date, time] = useFormatDateTime(created_at);
	const pairArray = pair.split('_');
	const [oneText, twoText] = splitText(pair || 'btc_eur');
	const sell = side === ETradeSide.SELL ? pairArray[0] : pairArray[1];
	const buy = side === ETradeSide.SELL ? pairArray[1] : pairArray[0];
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p className="td__text">{moment.utc(created_at).format('DD MMM, YYYY')}</p>
					<span className="td__more">{moment.utc(created_at).format('HH:mm:ss')}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Account Email</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<Link to={`/user-management/${String(user_id)}`}>{user.email}</Link>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral Fee</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">{roundingNumber(referral_fee, 'crypto')}</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral %</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">{referral_percent}</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sell</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>{sell.toUpperCase()}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Buy</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>{buy.toUpperCase()}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sold</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>
							{roundingNumber(quantity, sell)} {sell.toUpperCase()}
						</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Received</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>
							{roundingNumber(amount, buy)} {buy.toUpperCase()}
						</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Exchange Rate</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>
							{/* {roundingNumber(price_b2c2, buy, {
								cryptoCurrency: [
									'BTC',
									'LTC',
									'BCH',
									'ETH',
									'TRX',
									'XRP',
									'crypto',
									'eur',
									'aud',
									'usdc',
									'usdt',
									'gbr',
									'GBP',
									'USD',
								],
							})} */}
							{toFixedNumber(price_b2c2, twoText, undefined, 7)}
						</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>{roundingNumber(fee, 'crypto')}</p>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">TxID</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(uuid, 2, 2) || '-'}
						</span>
						{!!uuid && (
							<CopyToClipboard text={uuid.toString()} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="17" h="17" />
								</button>
							</CopyToClipboard>
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ReferralsTradeTableRow;
