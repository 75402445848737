import { useFormatDateTime } from 'hooks/useFormatDateTime';
import React, { FC } from 'react';
import { capitalize } from 'services/utils/strings';

import { notificationContainer } from 'services/utils/notificationContainer';
import moment from 'moment';
import { Link, useRouteMatch } from 'react-router-dom';
import { statusTitle } from '../../utils';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { IFiatHistoryRowProps } from '../../CryptoHistory/types';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const FiatHistoryRow: FC<IFiatHistoryRowProps> = ({ item }) => {
	const { path, url } = useRouteMatch();
	const [date, time] = useFormatDateTime(item.created_at);
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div>
					<p className="td-name--flex">{moment.utc(item.created_at).format('YYYY-MM-DD')}</p>
					<span className="td-more-info">{moment.utc(item.created_at).format('HH:mm:ss')}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{capitalize(item?.type)}</p>
			</div>
			<div className="td td--width_90">
				<p className="td-hidden-name">Currency</p>
				<div>
					<p className="td-name--flex">{item.asset.code.toUpperCase()}</p>
					<span className="td-more-info">{item?.asset?.name}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gross</p>
				<p>{roundingNumber(item.gross_fee, item.asset.code)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<div>
					<p>{roundingNumber(item.fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(item.net_fee, item.asset.code, true)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				<p>{item?.bank_account?.bank_name || item?.admin_requisite?.bank_name || '-'}</p>
				<span className="td-more-info">
					{item?.bank_account?.account_number || item?.admin_requisite?.account_number || '-'}
				</span>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Tx ID</p>
				<p className="copy-button">
					{/* <p>{item.id}</p> */}
					<Link
						to={{
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							pathname: `${url}/${item.id}`,
							state: {
								txId: item.id,
								fromTransfersFiatUser: true,
								userId: item.user_id,
							},
						}}
					>
						{item.id}
					</Link>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={`${item.status}-status`}>{statusTitle[item.status]}</span>
			</div>
		</div>
	);
};

export default FiatHistoryRow;
