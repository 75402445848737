import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Header from 'layouts-elements/Header/Header/Header';
import Footer from 'layouts-elements/Footer';
import { useHistory } from 'react-router';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const history = useHistory();
	// const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
	const [showHeader, setShowHeader] = useState(false);

	useEffect(() => {
		if (history.location.pathname === '/maintenance') {
			setShowHeader(false);
			return;
		}
		setShowHeader(true);
	}, [history]);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>

			{showHeader && <Header />}
			<main className="main">{children}</main>
			<Footer />
		</>
	);
};

export default Dashboard;
