import { useFormatDateTime } from 'hooks/useFormatDateTime';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { capitalize, shortNotation } from 'services/utils/strings';
import { roundingNumber } from 'services/utils/roundingNumber';
import IconSvg from 'ui/Svg/IconSvg';
import moment from 'moment';
import { statusTitle } from '../../utils';
import { ICryptoHistoryRowProps } from '../types';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const CryptoHistoryRow: FC<ICryptoHistoryRowProps> = ({ item }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const { path, url } = useRouteMatch();

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div>
					<p className="td-name--flex">{moment.utc(item.created_at).format('YYYY-MM-DD')}</p>
					<span className="td-more-info">{moment.utc(item.created_at).format('HH:mm:ss')}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{capitalize(item.type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.asset.code.toUpperCase()}</p>
				<span className="td-more-info">{item.asset.name}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Gross</p>
				<div>
					<p>{roundingNumber(item?.gross_fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<div>
					<p>{roundingNumber(item.fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(item?.net_fee, item.asset.code, true)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				<div className="copy-button">
					{item.destination && (
						<>
							<span className="copy-button__text">{shortNotation(item.destination, 4, 4)}</span>
							<CopyToClipboard text={item.destination} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="17" h="17" />
								</button>
							</CopyToClipboard>
						</>
					)}
					{!item.destination && item.address && (
						<>
							<span className="copy-button__text">
								{shortNotation(item.address?.address, 4, 4)}
							</span>
							<CopyToClipboard text={item.address?.address} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="17" h="17" />
								</button>
							</CopyToClipboard>
						</>
					)}
					{!item.destination && !item.address && '-'}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx Hash</p>
				<div className="copy-button">
					<span className="copy-button__text">{shortNotation(item.ext_tx_hash, 4, 4) || '-'}</span>
					{item.ext_tx_hash && (
						<CopyToClipboard text={item.ext_tx_hash} onCopy={handleCopy}>
							<button type="button">
								<IconSvg name="copy" w="17" h="17" />
							</button>
						</CopyToClipboard>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">TxID</p>
				<Link
					to={{
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						pathname: `${url}/${item.id}`,
						state: {
							txId: item.id,
							fromTransfersCryptoUser: true,
							userId: item.user_id,
						},
					}}
				>
					{item.id}
				</Link>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Status</p>
				<span className={`${item.status}-status`}>{statusTitle[item.status]}</span>
			</div>
		</div>
	);
};

export default CryptoHistoryRow;
