export const ROUTES = {
	adminPanel: {
		index: '/admin-panel',
		currency: {
			crypto: '/admin-panel/crypto',
			fiat: '/admin-panel/fiat',
			index: '/admin-panel/crypto',
		},
	},
	adminManagement: {
		index: '/admin-management',
		CREATE_ADMIN: '/admin-management/create',
		EDIT_ADMIN: '/admin-management/edit',
	},
	createBankAccount: '/create-bank-account',
	depositAccounts: '/deposit-accounts',
	userManagement: {
		index: '/user-management',
	},
	walletsManagement: {
		INDEX: '/wallet-balances',
		USER_BALANCES: '/wallet-balances/user-balances',
		LIQUIDITY: '/wallet-balances/liquidity',
		PLATFORM_BALANCES: '/wallet-balances/platform-balance',
		DEPOSIT_WALLETS: '/wallet-balances/deposit-wallets',
		PENDING_FEES: '/wallet-balances/pending-fees',
		DEPOSIT_ACCOUNTS: '/wallet-balances/deposit-accounts',
		SPREAD_MANAGEMENT: '/wallet-balances/spread-management-fee',
	},
	transfersAndTradeReports: {
		deposit: {
			crypto: '/transfers-and-trade-reports/deposit/crypto',
			fiat: '/transfers-and-trade-reports/deposit/fiat',
			index: '/transfers-and-trade-reports/deposit',
		},
		withdrawals: {
			crypto: '/transfers-and-trade-reports/withdrawals/crypto',
			fiat: '/transfers-and-trade-reports/withdrawals/fiat',
			index: '/transfers-and-trade-reports/withdrawals',
		},
		transfers: {
			index: '/transfers-and-trade-reports/transfers',
			fiat: '/transfers-and-trade-reports/transfers/fiat',
			crypto: '/transfers-and-trade-reports/transfers/crypto',
			pooledDeposit: '/transfers-and-trade-reports/transfers/pooled-deposit',
			profitWallet: '/transfers-and-trade-reports/transfers/profit-wallet',
		},
		transferLimits: {
			index: '/transfers-and-trade-reports/transfer-limits',
			fiat: '/transfers-and-trade-reports/transfer-limits/fiat',
			crypto: '/transfers-and-trade-reports/transfer-limits/crypto',
		},
		trades: {
			index: '/transfers-and-trade-reports/trades',
		},
		ledgerAggregated: {
			index: '/transfers-and-trade-reports/ledger-aggregated',
		},
		tradesLimits: {
			index: '/transfers-and-trade-reports/trades-limits',
			fiat: '/transfers-and-trade-reports/trades-limits/fiat',
			crypto: '/transfers-and-trade-reports/trades-limits/crypto',
		},
		pendingWithdrawal: {
			index: '/transfers-and-trade-reports/pending-withdrawal',
			fiat: '/transfers-and-trade-reports/pending-withdrawal/fiat',
			crypto: '/transfers-and-trade-reports/pending-withdrawal/crypto',
		},
		pendingDeposits: {
			index: '/transfers-and-trade-reports/pending-deposits',
			fiat: '/transfers-and-trade-reports/pending-deposits/fiat',
			crypto: '/transfers-and-trade-reports/pending-deposits/crypto',
		},
		ledger: {
			index: '/transfers-and-trade-reports/ledger',
		},
	},
	referralSystem: {
		index: '/referral-system',
		trades: '/referral-system/trades',
		referrals: '/referral-system/referrals',
		referralsByUser: '/referral-system/referrals/:userId',
	},
	messages: {
		index: '/messages/planned',
		sent: '/messages/sent',
	},
	User: {
		createUser: {
			index: '/createUser',
		},
	},

	UserResetRequestDefault: '/reset-request/users',

	UserResetRequest: {
		password: '/reset-request/users/password',
		twofa: '/reset-request/users/twofa',
	},
	AdminResetRequest: {
		password: '/reset-request/admin/password',
		twofa: '/reset-request/admin/twofa',
	},
	RequiredEmails: '/required-emails',
	AccountTabs: {
		ACCOUNT: '/account',
		ACCOUNT_MAIN: '/account/main',
		BALANCES: '/balances',
		DEPOSIT_HISTORY: '/deposit-history',
		WITHDRAWAL_HISTORY: '/withdrawal-history',
		TRANSACTION_HISTORY: '/transaction-history',
		TRANSFER_HISTORY: '/transfer-history',
		TRADE_HISTORY: '/trade-history/trade',
		REFERRAL: '/referral',
		REFERRAL_FEE: '/referral-fee',
		TRADING_LIMITS: '/trading-limits',
		TRANSFER_LIMITS: '/transfer-limits',
		PAYMENT_METHOD: '/beneficiaries',
		TRADE_MANAGEMENT: '/trade-management',
		FEE_MANAGEMENT: '/fee-management',
		REQUIRED_EMAILS: '/required-emails',
		CURRENCIES_EMAILS: '/currencies-emails',
	},
	CREATE_TWOFA: '/create-new-2fa',
	firstLogin2fa: '/first-login-2fa',
	CREATE_NEW_PASSWORD: '/create-new-password',
	login: '/login',
	TERMS_OF_USE: '/terms',
	PRIVACY_POLICY: '/privacy',
	MAINTENANCE: '/maintenance',
};
